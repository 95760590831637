import React, { useEffect, useState } from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import crypto from 'crypto';
import Head from 'next/head';
import { useRouter } from 'next/router';
import useTranslation from '@modules/hooks/useTranslation';
import useOverlay from '@modules/hooks/useOverlay';
import { Join, PreviousPage, User } from '@modules/state/user';
import LoginScreen from '@feature/user/login/Screen';
import Toast from '@components/Toast';
import LoginAlertModal from '@components/LoginAlertModal';

const Login = () => {
    const { t } = useTranslation('login');
    const text = t('common');

    const router = useRouter();
    const { type, email, page } = router.query;

    const setPreviousPage = useSetRecoilState(PreviousPage);

    const resetJoin = useResetRecoilState(Join);

    const user = useRecoilValue(User);

    const [otherLoginType, setOtherLoginType] = useState<string>('');
    const [otherLoginEmail, setOtherLoginEmail] = useState<string>('');

    const { isOpen: isOutWarningOpen, handleOpen: handleOutWarning, close: handleOutWarningClose } = useOverlay();

    const handleNaverLoginClick = () => {
        const rawToken = crypto.randomBytes(16).toString('base64');
        const state = encodeURIComponent(rawToken);

        const link = `${
            process.env.NEXT_PUBLIC_NODE_ENV === 'development'
                ? process.env.NEXT_PUBLIC_LOGIN_NAVER_LINK_DEV
                : process.env.NEXT_PUBLIC_LOGIN_NAVER_LINK
        }&state=${state}`;

        if (!link) return;
        window.location.href = link;
    };

    const handleKakaoLoginClick = () => {
        const link =
            process.env.NEXT_PUBLIC_NODE_ENV === 'development'
                ? process.env.NEXT_PUBLIC_LOGIN_KAKAO_LINK_DEV
                : process.env.NEXT_PUBLIC_LOGIN_KAKAO_LINK;

        if (!link) return;
        window.location.href = link;
    };

    const handleGoogleLoginClick = () => {
        const randomBuffer = crypto.randomBytes(1024);
        const randomBytes = new Uint8Array(randomBuffer);
        const state = crypto.createHash('sha256').update(randomBytes).digest('hex');

        const link = `${
            process.env.NEXT_PUBLIC_NODE_ENV === 'development'
                ? process.env.NEXT_PUBLIC_LOGIN_GOOGLE_LINK_DEV
                : process.env.NEXT_PUBLIC_LOGIN_GOOGLE_LINK
        }&state=${state}`;

        if (!link) return;
        window.location.href = link;
    };

    useEffect(() => {
        if (page === undefined) setPreviousPage('');
        else setPreviousPage(page as string);
    }, [page]);

    useEffect(() => {
        if (type === undefined && user.accessToken) router.push('/');
        if (type === 'withdraw') handleOutWarning();
        if (type === 'success') {
            resetJoin();
            router.push('/studio/text-to-speech');
        } else if (typeof type === 'string' && typeof email === 'string') {
            setOtherLoginEmail(email);
            setOtherLoginType(type);
        }
    }, [type]);

    return (
        <>
            <Head>
                <title>{t('title')}</title>
                <meta property="og:title" content={t('title')} />
            </Head>

            <LoginScreen
                onNaverLoginClick={handleNaverLoginClick}
                onKakaoLoginClick={handleKakaoLoginClick}
                onGoogleLoginClick={handleGoogleLoginClick}
            />

            {otherLoginType !== '' && (
                <LoginAlertModal email={otherLoginEmail} type={otherLoginType} onClose={() => setOtherLoginType('')} />
            )}

            {isOutWarningOpen && (
                <Toast
                    isToast={false}
                    iconSrc="/images/common/warning.svg"
                    text={text.outTitle}
                    guide={text.outGuide}
                    onCloseClick={handleOutWarningClose}
                />
            )}
        </>
    );
};

export default Login;
