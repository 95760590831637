import Link from 'next/link';
import useTranslation from '@modules/hooks/useTranslation';
import LoginButton from '@feature/user/login/components/LoginButton';
import styles from '@styles/user/login.module.scss';

interface Props {
    onNaverLoginClick: () => void;
    onKakaoLoginClick: () => void;
    onGoogleLoginClick: () => void;
}

const LoginScreen = ({ onNaverLoginClick, onKakaoLoginClick, onGoogleLoginClick }: Props) => {
    const { t } = useTranslation('login');
    const text = t('indexPage');

    return (
        <div className={styles.wrap}>
            <div id="container" className={styles.container}>
                <div className={styles.title_area}>
                    <Link href="/">
                        <img src="/logo.svg" alt="logo" width={108} height={54} />
                    </Link>
                    <Link href="/">
                        <img src="/VOLI-primary.svg" alt="VOLI" width={108} height={55} />
                    </Link>

                    <h1 className={styles.welcome}>{text.welcome}</h1>
                </div>
                <div className={styles.button_area}>
                    <LoginButton id="google" text={text.google} onClick={onGoogleLoginClick} />
                    <LoginButton id="naver" text={text.naver} onClick={onNaverLoginClick} />
                    <LoginButton id="kakao" text={text.kakao} onClick={onKakaoLoginClick} />

                    <hr />

                    <Link href="/login/email">
                        <a id="voli" className={styles.login_btn}>
                            {text.voli}
                        </a>
                    </Link>

                    <Link href="/user/password/new">
                        <a className={styles.underline_btn}>{t('common').findPWD}</a>
                    </Link>

                    <p className={styles.message}>
                        {t('common').guide[0]}
                        <Link href="/terms/service">
                            <a target="_blank">{t('common').guide[1]}</a>
                        </Link>
                        {t('common').guide[2]}
                        <Link href="/terms/privacy">
                            <a target="_blank">{t('common').guide[3]}</a>
                        </Link>
                        {t('common').guide[4]}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default LoginScreen;
